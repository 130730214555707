import { NavItem } from './nav-item/nav-item';


export const navItems: NavItem[] = [
 /* {
    navCap: 'MANAGEMENT',
  },*/
  {
    displayName: 'Domov',
    iconName: 'home',
    route: 'dashboards/dashboard1',
    routeName: 'domov',
  },
 /* {
    displayName: 'Fizične osebe',
    iconName: 'users',
    route: 'apps/customers',
    routeName: 'domov/fizične osebe',
  },
  {
    displayName: 'Pravne osebe',
    iconName: 'users',
    route: 'apps/law_customers',
    routeName: 'domov/pravne osebe',
  },*/
  {
    displayName: 'Imenik',
    iconName: 'users',
    route: 'apps/stranke',
    routeName: 'domov/stranke',
  },
  /*{
    displayName: 'Šifranti',
    iconName: 'code-plus',
    route: 'apps/employee',
    routeName: 'domov/šifranti',
  },*/
  {
    navCap: 'Dokumenti',
  },
  {
    displayName: 'Spisi',
    iconName: 'folder',
    route: 'apps/spisi',
    routeName: 'domov/spisi',
  },
  /*{
    displayName: 'Dokumenti spisov',
    iconName: 'file',
    route: 'apps/docs',
    routeName: 'domov/dokumenti',
  },*/
  {
    displayName: 'Organi',
    iconName: 'building',
    route: 'apps/organi',
    routeName: 'domov/organi',
  },
  {
    displayName: 'Fizična Pošta',
    iconName: 'mail',
    route: 'apps/posta',
    routeName: 'domov/posta',
  },
  {
    displayName: 'E-Pošta',
    iconName: 'at',
    route: 'apps/e-posta',
    routeName: 'domov/e-posta',
  },
  {
    displayName: 'Računi',
    iconName: 'receipt',
    route: 'apps/racuni',
    routeName: 'domov/racuni',
  },
  /*{
    displayName: 'Vplačila',
    iconName: 'credit-card-pay',
    route: 'apps/vplacila',
    routeName: 'domov/vplacila',
  },
  {
    displayName: 'Odvetniška tarifa',
    iconName: 'zoom-money',
    route: 'apps/tarifa',
    routeName: 'domov/tarifa',
  },*/
  {
    navCap: 'Produktivnost',
  },
  {
    displayName: 'Koledar',
    iconName: 'calendar-event',
    route: 'apps/calendar',
    routeName: 'domov/koledar',
  },
  {
    displayName: 'Naloge',
    iconName: 'ticket',
    route: 'apps/tickets',
    routeName: 'domov/naloge',
  },
  {
    displayName: 'Pravni svetovalec',
    iconName: 'message-chatbot',
    route: 'apps/pravni-svetovalec',
    routeName: 'domov/pravni-svetovalec',
    isBeta: true, // Add this flag to indicate that this is a beta feature
  },
  
  {
    navCap: 'Nastavitve',
  },
  {
    displayName: 'Nastavitve podjetja',
    iconName: 'registered',
    route: 'apps/roles',
    routeName: 'domov/uporabniki',
  },
  {
    displayName: 'Nastavitve uporabnika',
    iconName: 'user-circle',
    route: 'theme-pages/account-setting',
    routeName: 'domov/nastavitve računa',
  }
];
